export const projectsData = [
  {
    id: 1,
    projectName: "Education LMS",
    projectDesc: [
      `An LMS Site for education`,
      [
        "Front-End Using React",
        "Back-End using Node Express",
        "Database using MongoDB"
      ]
    ],
    tags: ["React", "Express", "MongoDB", "Node JS"],
    code: "https://github.com/OfficialSiddharthBisht/sid-education",
    demo: "https://sid-ed.vercel.app/",
    image:
      "./sid-ed-ss.png"
  },
  {
    id: 2,
    projectName: "Nykaa Clone",
    projectDesc: [
      `Nykaa is an E-commerce company have domain around cosmetic's`,
      [
        "Front-End Using React",
        "Back-End using Node Express",
        "Database using MongoDB"
      ]
    ],
    tags: ["React", "Express", "MongoDB", "Node JS"],
    code: "https://github.com/OfficialSiddharthBisht/nykaa-clone",
    demo: "https://nykaa-clone-siddharth.netlify.app/",
    image:
      "https://th.bing.com/th/id/OIP.eiIhwuSOWJvAgFDj0XOVtAHaDS?pid=ImgDet&rs=1"
  },
  {
    id: 3,
    projectName: "Tesla Clone",
    projectDesc: [
      "Tesla is accelerating the world's transition to sustainable energy with electric cars, solar and integrated renewable energy solutions for homes and businesses.",
      ["Solo Project", "Made Tesla Landing Page"]
    ],
    tags: ["React", "Redux"],
    code: "https://github.com/OfficialSiddharthBisht/tesla-clone",
    demo: "https://tesla-clone-rho.vercel.app/",
    image:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.f9OvjIR-m7YLjEqv-wJL-QHaJq%26pid%3DApi&f=1"
  },
  {
    id: 4,
    projectName: "E Commerce Backend NodeJS",
    projectDesc: [
      "E commerce Backend ",
      ["Solo Project", "Admin, User, Cart ,Filters, Search, Products, Orders"]
    ],
    tags: ["MongoDB", "ExpressJS", "NodeJS"],
    code: "https://github.com/OfficialSiddharthBisht/e-commerce-backend",
    demo: "https://github.com/OfficialSiddharthBisht/e-commerce-backend/wiki",
    image:
      "https://th.bing.com/th/id/OIP.ndX5N2ZGLTLjQWV8gier8QHaFj?w=226&h=180&c=7&r=0&o=5&dpr=1.25&pid=1.7"
  },
  {
    id: 5,
    projectName: "Discovery+ Clone",
    projectDesc: [
      "Discovery+ is a streaming service.",
      [
        "JSON Server + API Endpoints",
        "Data Fetching using API",
        "Search Series Functionality"
      ]
    ],
    tags: ["HTML", "CSS", "JavaScript"],
    code: "https://github.com/OfficialSiddharthBisht/Discovery-Clone",
    demo: "https://officialsiddharthbisht.github.io/Discovery-Clone/",
    image:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.dBgoyEfYljjeyDFAxa-twwHaEU%26pid%3DApi&f=1"
  },
  {
    id: 6,
    projectName: "React Native UI App",
    projectDesc: [
      "React native application a UI app.",
      [
        "styled components",
        "Images , View , SafeAreaView etc",
        "expo client"
      ]
    ],
    tags: ["React Native", "Mobile", "Android", "IOS"],
    code: "https://github.com/OfficialSiddharthBisht/react-native-app1",
    demo: "https://github.com/OfficialSiddharthBisht/react-native-app1#readme",
    image:
      "https://user-images.githubusercontent.com/80667996/239228388-606a1259-6383-45cd-9db1-202e30e0175c.jpg"
  },
  {
    id: 7,
    projectName: "Figma Mockup",
    projectDesc: [
      "React native application a UI app.",
      [
        "Images , View , SafeAreaView etc",
        "expo client"
      ]
    ],
    tags: ["UI/UX", "Figma", "User Flow", "Task Flow"],
    code: "https://www.figma.com/file/9GGK9LVp4F5vS64YM9cUs0/First-Mockup-E-com-v1?type=design&node-id=1-3&t=eNsx0p1YY8gX4CTm-0",
    demo: "https://www.figma.com/proto/9GGK9LVp4F5vS64YM9cUs0/First-Mockup-E-com-v1?type=design&node-id=1-3&scaling=scale-down&page-id=0%3A1",
    image:
      "./code.png"
  },
  {
    id: 8,
    projectName: "Lumen 5 Clone",
    projectDesc: [
      "A vanilla JS Project built on a week.",
      [
        "Landing Page",
      ]
    ],
    tags: ["HTML", "CSS", "JavaScript"],
    code: "https://github.com/OfficialSiddharthBisht/Lumen5-Video-Maker-Clone",
    demo: "https://officialsiddharthbisht.github.io/Lumen5-Video-Maker-Clone/",
    image:
      "https://officialsiddharthbisht.github.io/Lumen5-Video-Maker-Clone/Assets/Images/logo-white.png"
  },
  {
    id: 9,
    projectName: "FigJam E-Commerce",
    projectDesc: [
      "React e commerce app file and code structure design",
      [
        ""
      ]
    ],
    tags: ["UI/UX", "FigJam", "Design"],
    code: "https://www.figma.com/file/f0MNmGuRsER1HZK81HqXKy/e-commerce-design?type=whiteboard&node-id=0%3A1&t=eQrxJ6wPDmWR74rM-1",
    demo: "https://www.figma.com/file/f0MNmGuRsER1HZK81HqXKy/e-commerce-design?type=whiteboard&node-id=0%3A1&t=eQrxJ6wPDmWR74rM-1",
    image:
      "./code.png"
  },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/