export const experienceData = [
    {
        id: 1,
        company: 'FlameCloud',
        jobtitle: 'Full Stack Developer',
        startYear: '2022',
        endYear: '2023'
    },
    {
        id: 2,
        company: 'Bit Brothers',
        jobtitle: 'Backend Developer',
        startYear: '2023',
        endYear: '2023'
    },
]