export const educationData = [
    {
        id: 1,
        institution: "Masai School",
        course: "Full Stack Web Development",
        startYear: "2021",
        endYear: "2022",
      },
      {
        id: 2,
        institution: "Integrated Institure of Technology, Dwarka Delhi",
        course: "BCA",
        startYear: "2019",
        endYear: "2022",
      },
      {
        id: 3,
        institution: "Amtul's School ,Nainital",
        course: "Interschooling (Science And Computer Programming)",
        startYear: "2017",
        endYear: "2019",
      },
      {
        id: 4,
        institution: "Sanwal School, Nainital",
        course: "High School",
        startYear: "2004",
        endYear: "2017",
      }
]